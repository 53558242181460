import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { mergeMap, pairwise, startWith, switchMap } from 'rxjs/operators';
import { CacheControlService } from '../services/cache-control.service';
import { PlatformService } from '../services/platform.service';
import { UserService } from '../services/user/user.service';

export interface CacheControl {
  cacheKey: string;
  version: number;
}

export const initialRequests = ['/environment', '/cache/cf', '/country/list', '/currency/list/ss'];

@Injectable({
  providedIn: 'root',
})
export class CacheControlInterceptor implements HttpInterceptor {

  constructor(
    private _cache: CacheControlService,
    private _platform: PlatformService,
    private _user: UserService,
  ) {
  }

  /**
   * Can use from Environment cache
   * @private
   */

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this._platform.isBrowser || !this._isRequestToCms(req) || initialRequests.some(r => req.url.includes(r))) {
      return next.handle(req);
    }

    return this._user.auth$.pipe(
      startWith(false),
      pairwise(),
      switchMap(dataAuth => (
        dataAuth[0] !== dataAuth[1]) && dataAuth[1] ?
        !this._cache.cacheAuth$ ? this._cache.updateCacheAuth() : this._cache.cacheAuth$ :
        this._cache.cache$,
      ),
      mergeMap(cache => {
        const version = this._getCacheVersion(cache.data.cacheControl, req.url);

        if (version) {
          req = req.clone({
            setParams: { bcv: String(version) },
            setHeaders: this._user.auth ? {
              'UID': this._user.info?.id?.toString() || '',
              'UC': this._user.info?.country?.toString() || '',
            } : {},
          });
        }

        return next.handle(req);
      }),
    );
  }


  /**
   * Check request to CMS
   * @param req
   * @private
   */
  private _isRequestToCms(req: HttpRequest<any>): boolean {
    return req.url.startsWith(environment.cms_host) && req.method === 'GET';
  }

  /**
   * Get cache version
   * @private
   */
  private _getCacheVersion(cache: CacheControl[] = [], requestUrl): string {
    const cacheItem = cache.find(c => requestUrl.includes(c.cacheKey));
    return cacheItem ? String(cacheItem.version) : null;
  }
}
